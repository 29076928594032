import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07e9c2bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Timer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.endDate), 1),
    _createElementVNode("h2", null, "days " + _toDisplayString(_ctx.daysToWork), 1),
    _createElementVNode("h2", null, "seconds " + _toDisplayString(_ctx.secondsTosWork), 1),
    _createElementVNode("h2", null, "milliseconds " + _toDisplayString(_ctx.millisecondsTosWork), 1)
  ]))
}