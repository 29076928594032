
import { defineComponent } from "vue";
import moment from "moment";
const endData = moment("2021-12-09").add(31, "days");

export default defineComponent({
  name: "Timer",
  mounted() {
    setInterval(() => {
      this.secondsTosWork = endData.diff(moment(), "seconds");
      this.millisecondsTosWork = endData.diff(moment(), "milliseconds");
    }, 1000);
    setInterval(() => {
      this.millisecondsTosWork = endData.diff(moment(), "milliseconds");
    }, 10);
  },
  data() {
    const daysToWork = endData.diff(moment(), "days");
    const secondsTosWork = endData.diff(moment(), "seconds");
    const millisecondsTosWork = endData.diff(moment(), "milliseconds");

    return {
      endDate: endData.format("YYYY-MM-DD"),
      daysToWork,
      secondsTosWork,
      millisecondsTosWork,
    };
  },
});
