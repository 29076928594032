
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import Timer from "./components/Timer.vue";

@Options({
  components: {
    HelloWorld,
    Timer,
  },
})
export default class App extends Vue {}
